.buttonContainer
{
    display: inline;
    cursor: pointer;
    -moz-box-shadow: 0 0 6px #000;
    -webkit-box-shadow: 0 0 6px #000;
    box-shadow: 0 0 6px #000;
}

.buttonContainer3
{
    cursor: pointer;
    -moz-box-shadow: 0 0 6px #000;
    -webkit-box-shadow: 0 0 6px #000;
    box-shadow: 0 0 6px #000;
}

.buttonContainer2
{
    cursor: pointer;
}

.buttonContainer2Span
{
    border-bottom: 2px solid red;
}


.mainDiv {
    -moz-box-shadow: 0 0 10px #000;
    -webkit-box-shadow: 0 0 10px #000;
    box-shadow: 0 0 10px #000;
}


.arc_image_top {
    width: 100px;
    height: 100px;
    background-color: red;
}

